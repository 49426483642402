@tailwind base;
@tailwind components;
@tailwind utilities;

/* Custom scrollbar styling for WebKit browsers (Chrome, Safari, Edge) */
.custom-scrollbar {
  /* Always show the scrollbar */
  overflow-y: scroll;
}

/* WebKit browsers */
.custom-scrollbar::-webkit-scrollbar {
  width: 8px; /* Thin scrollbar */
  background-color: transparent; /* Transparent track */
}

/* Scrollbar thumb */
.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: rgba(74, 74, 74, 0.7); /* Slightly transparent thumb */
  border-radius: 4px; /* Rounded thumb for a modern look */
}

/* Scrollbar track */
.custom-scrollbar::-webkit-scrollbar-track {
  background-color: transparent; /* Transparent track for subtle effect */
}

/* On hover or active, increase opacity */
.custom-scrollbar:hover::-webkit-scrollbar-thumb,
.custom-scrollbar:active::-webkit-scrollbar-thumb {
  background-color: rgba(46, 46, 46, 1); /* Darker color on hover */
}

